import React from 'react';
import { Box, Text } from 'rebass/styled-components';
import styled from 'styled-components';

const Wrapper = styled(Box)`
  text-align: center;
  h2 {
    font-weight: normal;
    font-size: 50px;
    line-height: 1.1;
  }

  p {
    font-size: 21px;
    padding: 0 10%;
  }

  @media (max-width: 750px) {
    text-align: left;

    h2 {
      font-size: 26px;
    }
    p {
      font-size: 17px;
      padding: 0;
    }
  }
`;

const SectionIntro = ({ title, heading, ...props }: any) => (
  <Wrapper width={1} maxWidth="850px" mx="auto" {...props}>
    <Text as="h2" lineHeight={1.1}>
      {title}
    </Text>
    {heading && (
      <Text as="p" fontSize={[17, 21]} lineHeight={1.6} mt={15}>
        {heading}
      </Text>
    )}
  </Wrapper>
);

export default SectionIntro;
