import React from 'react';

const ModalClose = () => (
  <svg width="100%" height="100%" viewBox="0 0 233.53 233.53">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g opacity="0.75">
          <circle cx="116.77" cy="116.77" r="113.77" fill="#fff" />
        </g>
        <path
          d="M116.77,233.53A116.77,116.77,0,1,1,233.53,116.77,116.9,116.9,0,0,1,116.77,233.53ZM116.77,6A110.77,110.77,0,1,0,227.53,116.77,110.89,110.89,0,0,0,116.77,6Z"
          fill="#fff"
        />
        <rect
          x="113.77"
          y="51.1"
          width="6"
          height="131.33"
          transform="translate(-48.37 116.77) rotate(-45)"
          fill="#a59d94"
        />
        <rect
          x="51.1"
          y="113.77"
          width="131.33"
          height="6"
          transform="translate(-48.37 116.77) rotate(-45)"
          fill="#a59d94"
        />
      </g>
    </g>
  </svg>
);

export default ModalClose;
