import { motion } from 'framer-motion';
import { graphql } from 'gatsby';
import React, { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet';
import { InView } from 'react-intersection-observer';
import { animated, useTrail } from 'react-spring';
import { Box, Flex, Image, Text } from 'rebass/styled-components';
import styled from 'styled-components';

import { PrismicRichText } from '@prismicio/react';

import ModalClose from '../components/icons/ModalClose';
import Layout from '../components/Layout';
import Modal from '../components/Modal';
import SectionIntro from '../components/SectionIntro';
import Plus from '../static/images/join-us.png';

const Container = styled(Box)``;
const TeamWrapper = styled(Flex)`
  padding: 225px 5% 0;

  @media (max-width: 750px) {
    padding-top: 150px;
  }

  > div:nth-child(1) {
    @media (max-width: 750px) {
      padding: 0 3%;
    }
  }

  > div:nth-child(2) {
    margin-top: 100px;
    @media (max-width: 750px) {
      margin-top: 50px;
    }
  }
`;

const NetworkWrapper = styled(Box)`
  padding: 80px 8%;
  @media (max-width: 750px) {
    padding: 40px 8%;
  }

  > div:nth-child(2) {
    margin: 100px auto 0;
    @media (max-width: 750px) {
      margin-top: 40px;
      flex-direction: column;
    }

    > div:nth-child(1) {
      width: 46%;
      @media (max-width: 750px) {
        width: 100%;
      }
      > div {
        font-size: 21px;
        margin-bottom: 40px;
        @media (max-width: 1000px) {
          font-size: 15px;
          margin-bottom: 20px;
        }
      }

      > div:nth-child(1) {
        font-size: 44px;
        line-height: 1;
        margin-bottom: 40px;
        @media (max-width: 750px) {
          font-size: 25px;
          line-height: 1.2;
          margin-bottom: 20px;
        }
      }
    }
    > div:nth-child(2) {
      width: 46%;
      @media (max-width: 750px) {
        width: 100%;
        margin-bottom: -50px;

        :before {
          content: '';
          position: absolute;
          left: 0;
          width: 100vw;
          height: calc(200%);
          background-color: #fff;
          margin-top: -50px;
        }
      }
    }
  }
`;

const GridContainer = styled(Flex)`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  max-width: 1440px;
  width: 100%;
  margin: 150px auto 0 !important;

  @media (max-width: 750px) {
    margin-top: 50px !important;
  }
`;

const SocialLink = styled.a<any>`
  padding: 2px;
  :before {
    content: ${props => `'${props.content}'`};
    font-family: 'HVIcons';
    color: #a49d93;
    margin-right: 8px;
    transition: color 250ms ease-in-out;
  }
  :hover:before {
    color: ${props => props.color};
  }
`;

const AnimatedContainer = animated(Box);

const ActionContainer = styled(Box)`
  position: absolute;
  top: 45px;
  right: 40px;
  width: 50px;
  height: 50px;
  z-index: 100;
  svg {
    cursor: pointer;
    transition: opacity 300ms ease-in-out;

    &:hover {
      opacity: 0.65;
    }
  }
  @media (max-width: 750px) {
    width: 30px;
    height: 30px;
    top: 25px;
    right: 25px;
  }
`;

const Member = styled(Box)`
  cursor: pointer;
  width: calc(100% / 3 - 6%);
  margin: 0 3% 90px !important;

  @media (max-width: 750px) {
    width: 44%;
    margin-bottom: 50px;
  }

  .team-member__image {
    margin-bottom: 35px;
    overflow: hidden;
    position: relative;
    padding-bottom: 130%;

    img {
      position: absolute;
      filter: grayscale(1);
      transition: all 800ms ease-in-out;
    }
  }

  .team-member__name {
    font-family: 'NoeDisplayMedium', Georgia, serif;
    color: #424143;
    font-size: 21px;
    line-height: 1.1;
    padding-right: 20px;
    transition: color 250ms ease-in-out;

    :after {
      content: '–';
      display: block;
      padding-bottom: 2px;
    }
  }

  .team-member__title {
    font-family: 'FFMarkWebProBook', 'Helvetica', Arial, sans-serif;
    font-size: 14px;
    line-height: 1.3;
    color: #a49d93;
    padding-right: 20px;
    margin-bottom: 25px;
    transition: color 250ms ease-in-out;
  }

  .team-member__btn {
    color: #a49d93;
    transition: color 250ms ease-in-out;
    font-family: 'FFMarkWebProBook', 'Helvetica', Arial, sans-serif;
    display: inline-block;
    font-size: 15px;
    line-height: 1;

    :before {
      content: '';
      display: block;
      background-color: #a49d93;
      width: 0;
      height: 1px;
      top: 23px;
      position: relative;
      transition: width 450ms ease-in-out;
    }

    :after {
      content: '\\e900';
      font-size: 9px;
      font-family: 'HVIcons';
      display: inline-block;
      margin-left: 10px;
      top: -1px;
      position: relative;
      transition: all 450ms ease-in-out;
    }
  }

  :hover {
    .team-member__image img {
      filter: grayscale(0);
      transform: scale(1.05) translateZ(0);
    }

    .team-member__name {
      color: ${props => props.theme.colors.green};
    }

    .team-member__title {
      color: #424143;
    }

    .team-member__btn:before {
      width: calc(100% + 5px);
    }

    .team-member__btn:after {
      transform: translateX(4px);
    }
  }
`;

const TeamModal = styled(Flex)`
  overflow-y: scroll;
  .team-modal__name {
    font-family: 'NoeDisplayMedium', Georgia, serif;
    font-size: 44px;
    line-height: 1.1;
    color: #424143;

    :after {
      content: '-';
      display: block;
    }
  }

  >div: nth-child(1) {
    width: 45%;
    padding: 125px 75px 100px;

    @media (max-width: 1000px) {
      width: 55%;
    }
    @media (max-width: 750px) {
      width: 100%;
      margin-left: 0;
      margin-top: 300px;
      padding: 125px 25px 100px;
      background: -webkit-gradient(
        linear,
        left bottom,
        left top,
        color-stop(85%, #efefef),
        to(rgba(239, 239, 239, 0))
      );
      background: linear-gradient(to top, #efefef 85%, rgba(239, 239, 239, 0));
    }
  }

  >div: last-of-type {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center left;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 0;

    :before {
      content: '';
    }

    @media (max-width: 750px) {
      height: 400px;
      background-position: right top;

      :before {
        bottom: 0;
        left: 0;
        position: absolute;
        z-index: -5;
        background: -webkit-gradient(
          linear,
          left bottom,
          left top,
          from(#efefef),
          to(rgba(239, 239, 239, 0))
        );
        background: linear-gradient(to top, #efefef, rgba(239, 239, 239, 0));
        width: 100%;
        height: 100%;
      }
    }
  }
`;

const Network = styled(Box)<{ inView: boolean }>`
  width: calc(100% / 3 - 6%);
  opacity: ${props => (props.inView ? '1' : '0')};
  transform: ${props =>
    props.inView ? 'translateY(0px)' : 'translateY(20px)'};
  transition: opacity 0.75s cubic-bezier(0.5, 0, 0, 1) 0s,
    transform 0.75s cubic-bezier(0.5, 0, 0, 1) 0s;

  &.plus {
    display: none;
    @media (max-width: 750px) {
      display: none;
    }
  }

  @media (max-width: 500px) {
    width: 40%;
  }

  .network-member__image {
    border-radius: 100%;
    overflow: hidden;

    :before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }

  > div:nth-child(2) {
    font-family: 'FFMarkWebProMedium', 'Helvetica', Arial, sans-serif;
    @media (max-width: 1000px) {
      font-size: 13px;
    }
  }
`;

const TouchButton = styled.a`
  border: 1px solid #1eaea3;
  color: #1eaea3;
  padding: 15px 20px 18px;
  border-radius: 50px;
  display: inline-block;
  transition: all 500ms ease-in-out;
  cursor: pointer;

  :hover {
    background-color: #1eaea3;
    color: #fff;
  }

  :after {
    content: '\\e900';
    font-size: 9px;
    font-family: 'HVIcons';
    display: inline-block;
    margin-left: 10px;
    top: -1px;
    position: relative;
    transition: all 450ms ease-in-out;
  }
`;

const OurTeamPage: React.FC<any> = ({ data }) => {
  const {
    title,
    heading,
    members,
    networks,
    network_title,
    network_description,
    network_label,
    network_link,
  } = data.prismicTeam.data;

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<any>({});

  const handleSelect = (member: any) => {
    setSelected(member);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const trail = useTrail(members.length, {
    opacity: 1,
    from: { opacity: 1 },
  });

  return (
    <Layout>
      <Helmet>
        <title>Our Team</title>
      </Helmet>
      <Container>
        <TeamWrapper flexDirection="column">
          <SectionIntro title={title} heading={heading} />
          <GridContainer>
            {trail.map((style, idx) => {
              const { member } = members[idx];
              const { avatar, name, title } = member.document.data;

              return (
                <Member
                  key={idx}
                  onClick={() => handleSelect(member.document.data)}
                >
                  <motion.div
                    initial={{
                      opacity: 0,
                    }}
                    animate={{ opacity: 1 }}
                    transition={{
                      ease: [0.68, 0.55, 0.27, 1],
                      duration: 0.4,
                      delay: 0.1 * idx,
                    }}
                  >
                    <Box className="team-member__image">
                      <Image draggable={false} src={`${avatar.url}`} />
                    </Box>
                    <Text sx={{}} className="team-member__name">
                      {name}
                    </Text>
                    <Text className="team-member__title">{title}</Text>
                    <Text className="team-member__btn">View Bio</Text>
                  </motion.div>
                </Member>
              );
            })}
          </GridContainer>
        </TeamWrapper>

        <NetworkWrapper backgroundColor="#F4F2EF">
          <SectionIntro title={network_title} />
          <Flex width={1} maxWidth={1440}>
            <Box
              mr="8%"
              mb={100}
              sx={{
                p: {
                  fontSize: [15, 15, 21],
                  lineHeight: 1.6,
                  marginBottom: [20, 20, 30],
                },
                h2: {
                  fontFamily: `"NoeDisplayMedium",Georgia,serif`,
                  fontSize: [25, 25, 44],
                  fontWeight: 400,
                  lineHeight: [1.2, 1.2, 1],
                  margin: 0,
                  marginBottom: 30,
                },
              }}
            >
              <PrismicRichText field={network_description.richText} />
              <TouchButton href={network_link.url}>{network_label}</TouchButton>
            </Box>
            <Flex flexWrap="wrap" justifyContent="space-between">
              {networks.map(
                ({ image, name, network_title }: any, idx: number) => (
                  <Fragment key={idx}>
                    <InView triggerOnce rootMargin="-100px 0px">
                      {({ inView, ref }) => (
                        <Network m="0 3% 50px" ref={ref} inView={inView}>
                          <Box
                            className="network-member__image"
                            width={1}
                            mb={20}
                            sx={{
                              background: `url(${image.url}) no-repeat center / cover`,
                            }}
                          />
                          <Text pb="5px">{name}</Text>
                          <Text fontSize={12} sx={{ whiteSpace: 'pre' }}>
                            {network_title}
                          </Text>
                        </Network>
                      )}
                    </InView>
                    {idx === networks.length - 1 && (
                      <InView triggerOnce rootMargin="-100px 0px">
                        {({ inView, ref }) => (
                          <Network
                            className="plus"
                            m="0 3% 50px"
                            ref={ref}
                            inView={inView}
                          >
                            <Box
                              className="network-member__image"
                              width={1}
                              mb={20}
                              style={{
                                background: `url(${Plus}) no-repeat center / cover`,
                              }}
                            />
                            <Text pb="5px">Want to join us?</Text>
                            <Text fontSize={12} sx={{ whiteSpace: 'pre' }}>
                              Tap to get in touch?
                            </Text>
                          </Network>
                        )}
                      </InView>
                    )}
                  </Fragment>
                )
              )}
            </Flex>
          </Flex>
        </NetworkWrapper>

        <Modal key="modal" open={open} handleClose={handleClose} scroll={false}>
          <TeamModal flexDirection="column">
            <Box sx={{ zIndex: 600 }}>
              <Text className="team-modal__name">{selected.name}</Text>
              <Flex mb={30}>
                <Text
                  color="#424143"
                  fontFamily='"FFMarkWebProBook","Helvetica",Arial,sans-serif'
                  mr="15px"
                >
                  {selected.title}
                </Text>
                {selected.twitter && (
                  <SocialLink
                    href={selected.twitter}
                    content="\e905"
                    color="#1da1f2"
                  />
                )}
                {selected.linkedin && (
                  <SocialLink
                    href={selected.linkedin}
                    content="\e903"
                    color="#0077b5"
                  />
                )}
              </Flex>
              <Text
                fontSize={15}
                sx={{ whiteSpace: 'pre-wrap', lineHeight: 1.8 }}
              >
                {selected.background}
              </Text>
            </Box>
            <ActionContainer onClick={() => setOpen(false)}>
              <ModalClose />
            </ActionContainer>
            <Box
              sx={{
                background: `url(${
                  (selected.avatar_bg || {}).url
                }) no-repeat center / cover`,
              }}
            ></Box>
          </TeamModal>
        </Modal>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query {
    prismicTeam {
      data {
        title
        heading
        members {
          member {
            document {
              __typename
              ... on PrismicMembers {
                data {
                  avatar {
                    url
                  }
                  avatar_bg {
                    url
                  }
                  name
                  title
                  background
                  linkedin
                  twitter
                }
              }
            }
          }
        }
        network_title
        network_description {
          richText
        }
        network_label
        network_link {
          url
        }
        networks {
          image {
            url
          }
          name
          network_title
        }
      }
    }
  }
`;

export default OurTeamPage;
